import React  from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";  

class CheckoutForm extends React.Component {
   
  
  handleSubmit = async(event)  => {
    
    event.preventDefault();
      
    const { stripe, elements } = this.props;
    
    if (!stripe || !elements) {
      return;
    }
    /* Code for subscription Starts */
    const email =localStorage.getItem("email");
    const plan  =JSON.parse(localStorage.getItem("plan"));
    const planID = plan.plan_id;

    const card = elements.getElement(CardElement); 
    const {source} = await stripe.createSource(card, email);
    //Create Token based 
    const card_token = await stripe.createToken(card);
    // // create a payment method
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: elements.getElement("card"),
      billing_details: {
        //name:"Customer",
        email:email,
      },
    });

     //call the backend to create subscription
     //https://wizar-dev-backend.theintellify.net/api/v1
     //http://127.0.0.1:8000/api/v1/create-payment-intent
    const response = await fetch("https://wizar-dev-backend.theintellify.net/api/v1/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
    },
    body: JSON.stringify({
        paymentMethod: paymentMethod.paymentMethod.id,
        name:"Customer",
        email:email,
        currency: "usd", 
        planID:planID,
        //priceId:paymentMethod.id,
        source:source,
        card_token:card_token
      })
    });
    
    if(planID===2 || planID==3)
    {
      const data = await response.json();
      const confirm = await stripe.confirmCardPayment(data.clientSecret);
      console.log("Confirm OBJ"+JSON.stringify(confirm));
      if (confirm.error) return alert("Payment unsuccessful!");
      alert("Payment Successful! Subscription active.");
      window.location.href="/otp";
    }
    if(planID===1)
    {
      window.location.href="/otp";
    }
    

    
    //  await fetch("http://127.0.0.1:8000/api/v1/create-payment-intent", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     paymentMethod: paymentMethod.paymentMethod.id,
    //     name:"Customer",
    //     email:email,
    //     currency: "usd", 
    //     planID:planID,
    //     //priceId:paymentMethod.id,
    //     source:source
    //   }),
    //   }).then((res) =>{ 
    //        //window.location.href="/otp";
    //      //res.json()
    //   });
  };

  
  render() {
    return (
      <div>
        
        <form onSubmit={this.handleSubmit}>
          <CardSection   />
          <button disabled={!this.props.stripe} className="btn-pay btn-sm btn-primary mt-2">
           Pay Now
          </button>
        </form>


        
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
import React from 'react'

const Success = () => {
  return (
    <div className='mt-5'>
        <center>
        <h3 className='text-success'>You have successfully subscribed , Please check you mail to proceed futher.</h3>
        <h5><b>Thanks</b></h5>
        </center>
    </div>
  )
}

export default Success
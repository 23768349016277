import axios from 'axios';
import React, { useState,useContext } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import { useNavigate } from 'react-router-dom';



 
const Profile = () => {

    
    //const navigate = useNavigate();
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    var profileDate = new Date( auth_user.created_at );   
    
    const [profileInput,setProfile] = useState({
        email:'',
        name:'',
        error_list:[]
    });
    const handleInput =(e)=>{
        e.persist();
        setProfile({...profileInput,[e.target.name]:e.target.value});
        console.log(profileInput);   
    }

    const updateProfileSubmit=(e)=>{
        e.preventDefault();
        const data = {
         userid:auth_user.id,   
         name:(profileInput.name)? profileInput.name : auth_user.name
        }
        
        axios.post('/updateprofile',data).then(res=>{
            
         if(res.data.status===200)
         {    
            // localStorage.setItem("auth_token",res.data.token);
            localStorage.setItem("auth_name",res.data.auth_name);
            // localStorage.setItem("email",res.data.email);
            localStorage.setItem("auth_user",JSON.stringify(res.data.user));
             //console.log(JSON.stringify(res.data.user));
             
             
         } else{
             //alert("Email is Already Taken");
             setProfile({...profileInput, error_list: res.data.validation_errors });
            //console.log("error_list"+JSON.stringify(profileInput));
         }

        }).catch(err => {
            //alert('Email is already taken');
            //setProfile({...profileInput, error_list:err.response.data.message});             
        });
 }

    return (
        <>

            <Navbar />
            <Sidebar></Sidebar>
            
            
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Profile </h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content" style={{"height":"570px"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-8 mx-auto'>
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Profile Details</h3>
                                    <h2>{ profileInput ? profileInput.error_list  :''}</h2>
                                </div>
                                <form  onSubmit={updateProfileSubmit} className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label  className="col-sm-2 col-form-label">Username</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control"  name='name' defaultValue={auth_user.name} id="inputEmail3" placeholder="username" onChange={handleInput} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label  className="col-sm-2 col-form-label">Email</label>
                                            <div className="col-sm-10">
                                                <input type="email" readOnly="readonly" className="form-control" id="inputEmail3" placeholder="Email" defaultValue={auth_user.email} onChange={handleInput}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label  className="col-sm-3 col-form-label">Profile Created At</label>
                                            <div className="col-sm-9">
                                            <h6><span className="badge badge-secondary">
                                        
                                            {moment(auth_user.created_at).format("DD/M/Y")}
                                            </span></h6>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-info">Update</button>
                                        
                                    </div>
                                    
                                </form>
                            </div>
                            </div>  
                        </div>
                    </div>

                </section>
            </div>
            
            <footer className="main-footer">
                <strong>Copyright &copy; 2023-2024 <a href="/">wizAR</a>.</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.0.4
                </div>
            </footer> 

        </>
    )
}

export default Profile
import axios from 'axios';
import React, { useEffect, useState,useContext } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
const Sidebar = () => {
 
  let  data  = useContext(AuthContext);

  
  const navigate = useNavigate();
 
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);

  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;


  const config = {
    headers: {
      Authorization: bearer_token
    }
  };


  const setNavigationMenu = () => {

    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
    const menuURL = '/navigationmenu';
    const bodyParameters = {
      user: auth_user
    };

    setLoading(true);
    axios.post(menuURL, bodyParameters, config).then(res => {

      if (res.data.status === true) {
        setMenu(res.data.response);
        setLoading(false);
      }


    }).catch((err) => {
    });


  }

  useEffect(() => {
    setNavigationMenu();
  }, [])

  const logoutUser = () => {
    localStorage.clear();
    window.location= '/login';
  }

  return (

    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link">
          <img src="/admin/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
          <span className="brand-text font-weight-light">User</span>
        </Link>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src="/admin/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="" />
            </div>
            <div className="info">
              <Link href="#" className="d-block"> {auth_user ? auth_user.name : ''}</Link>
            </div>
          </div>
          <nav className="mt-2">
           
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {loading ? <div className="overlay" ><i className="fas fa-2x fa-sync-alt fa-spin text-white"></i></div> :
                menu.map((menuaccess, index) => (

                  (menuaccess.id === 17 ?
                    <li className="nav-item" key={menuaccess.id}>
                      <Link to="/profile" className="nav-link" key={index}>
                        <i className="nav-icon far fa-user text-info"></i>
                        <p>Profile</p>
                      </Link>
                    </li>
                    : menuaccess.id === 18 ?
                      <>
                        <li className="nav-item" key={menuaccess.id}>
                          <Link to="/settings" className="nav-link" key={index}>
                            <i className="nav-icon fas fa-cog"></i>
                            <p>
                              Settings
                              <span className="right badge badge-danger">API KEY / Secret</span>
                            </p>
                          </Link>
                        </li>
                      </>
                      : menuaccess.id === 19 ?
                        <>
                          <li className="nav-item " key={menuaccess.id}>
                            <Link to="/products" className="nav-link" key={index}>
                              <i className="nav-icon fab fa-product-hunt"></i>
                              <p>
                                Products
                              </p>
                            </Link>
                          </li>
                        </>
                        : menuaccess.id === 32 ?
                          <>
                            <li className="nav-item " key={menuaccess.id}>
                              <Link to="/userpayments" className="nav-link" key={index}>
                                <i className="nav-icon fas fa-credit-card"></i>
                                <p>
                                  Payments
                                </p>
                              </Link>
                            </li>
                          </>
                          //24 for customer access
                        : menuaccess.id === 28 ? // 28 Customer Access for admin 
                          <>
                            <li className="nav-item " key={menuaccess.id}>
                              <Link to="/requests" className="nav-link" key={index}>
                                <i className="nav-icon fas fa-paper-plane"></i>
                                <p>
                                  Requests
                                </p>
                              </Link>
                            </li>
                          </>
                        : menuaccess.id === 24 ? <>
                          <li className="nav-item " key={22}>
                            <Link to="/customers" className="nav-link" >
                              <i className="nav-icon fas fa-users"></i>
                              <p>
                                Customers
                              </p>
                            </Link>
                          </li>
                        </>
                          : "")
                ))
              }




              {/* <li className="nav-item" key={23}>
                <Link to="/requests" className="nav-link" >
                  <i className="nav-icon fas fa-paper-plane"></i>
                  <p>
                    Requests
                  </p>
                </Link>
              </li> */}
              {/* <li className="nav-item " key={'change-password'} >
                <Link to="/changepassword" className="nav-link">
                  <i className="nav-icon fas fa-key"></i>
                  <p>
                    Change Password
                  </p>
                </Link>
              </li> */}
              <li className="nav-item " key={'logout'}>
                <Link to="/" className="nav-link" onClick={() => { if (window.confirm('Are you sure you want to logout ?')) { logoutUser() }; }}>
                  <i className="nav-icon fas fa-sign-out-alt"></i>
                  <p>
                    Logout
                  </p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
import React, { useEffect, useState } from 'react';
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar'; 
import {Link ,useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment/moment';



const ProductView = (props) => {
        
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [inputs,setInputs] = useState({});
    const [feedbacklist, setFeedbackList] = useState([]);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
      headers: {
        Authorization: bearer_token
      }
    };
    useEffect(()=>{
        fetchProduct();
    },[]);


    const fetchProduct = () =>{
        setLoading(true);
        axios.get('/products/'+id+'/edit',config).then((res)=>{
            console.log("feedbacks"+JSON.stringify(res.data.feedbacks));
            setFeedbackList(res.data.feedbacks);           
            setInputs({
                product_name:res.data.product_name,
                frontend_url:res.data.frontend_url,
                status:res.data.status,
                feedback: res.data.feedback,
                product_images:res.data.product_images,
                product_models:res.data.product_models,
            });
            setLoading(false);
        });
    }


  return (
    <>
    <Navbar/>
    <Sidebar></Sidebar>
    <div className="content-wrapper">
    <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
                <h1 className="m-0 text-dark">Product View</h1>
            </div>
            <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                <li className="breadcrumb-item active">Product View</li>
                </ol>
            </div>
            </div>
        </div>
    </div>        
    <section className="content">
        <div className="container-fluid">
            <div className='row'>
            <div className='col-12'>
                      <div className="card card-dark">
                        <div className="card-header">
                          <h3 className="card-title">Product View</h3>
                          <Link to="/products" className='btn btn-sm btn-primary float-right'>Back</Link>
                          
                        </div>
                        <div className="card-body">
                            
                            <div className='row'>
                            <h5>Basic Information</h5>
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Value</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Product Name</td>
                                        <td>{inputs.product_name}</td>
                                        <td className="text-right py-0 align-middle">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Frontend URL</td>
                                        <td>{inputs.frontend_url}</td>
                                        <td className="text-right py-0 align-middle">
                                        
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Current Status</td>
                                        <td>
                                         { inputs.status==='1' ?'Is Requested'  :''}  
                                         { inputs.status==='2' ? 'Is Rejected'  :'' }
                                         { inputs.status==='3' ? 'In progress'  :'' }
                                         { inputs.status==='4' ? 'Is Review'  : '' }
                                        </td>
                                        <td className="text-right py-0 align-middle">
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                                  
                            </div>
                        </div>
                        <div class="row">
                                                    
                                            <div class="col-md-10 mx-auto">
                                             {  feedbacklist.length>0 ?
                                                <h4 className='p-3 mb-2 text-info text-bold'>Feedback</h4>
                                                :""}

                                                <div class="timeline">
                                                    {feedbacklist ? feedbacklist.map((feed, index) => (
                                                        <>
                                                        <div class="time-label">
                                                        <span class="bg-red"> { moment(feed.created_at).format("DD/MM/YYYY")  }</span>
                                                    </div>
                                                    { feed.feedback_type==="2" ?  
                                                        <div>
                                                            <i class="fas fa-envelope bg-blue"></i>
                                                            <div class="timeline-item">
                                                                <span class="time"><i class="fas fa-clock"></i> { moment(feed.created_at).format("hh:mm a") }</span>
                                                                <h3 class="timeline-header"><span className='text-bold text-primary text-capitalize'>{feed.users[0].name}</span> </h3>
                                                                <div class="timeline-body">
                                                                    { feed.feedback_text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                      :
                                                    <div>
                                                        <i class="fas fa-user bg-green"></i>
                                                        <div class="timeline-item">
                                                            <span class="time"><i class="fas fa-clock"></i>  { moment(feed.created_at).format("hh:mm a") }</span>
                                                            <h3 class="timeline-header no-border"><span className='text-bold text-success text-capitalize'>{feed.users[0].name}</span> </h3>
                                                            <div className='timeline-body'>{ feed.feedback_text}</div>
                                                        </div>
                                                    </div>
                                                     } 
                                                        </>

                                                        ))    : ''}
                                                        { feedbacklist.length >0 ? 
                                                            <div>
                                                            <i class="fas fa-clock bg-gray"></i>
                                                        </div> 
                                                          :'' }
                                                        
                                                    
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>
                        <hr/>
                        <h4 className='ml-3'>Product Images</h4>
                        <div className='row'>
                            { (inputs.product_images && inputs.product_images !=="") ? 
                                  inputs.product_images.map((images,index)=>{
                                    return(<div className="col-md-3 col-sm-6 col-12 ml-1">
                                    <div class="info-box ">
                                        <div class="info-box-content">
                                        <img className="img img-responsive border-2" width={250}  src={images.url} alt=""/>
                                        <div>{images.file_name}</div>
                                        </div>
                                    </div>

                                    {/* <img className="img img-responsive border-2" style={{"border":" 2px solid #705f39",
    "border-radius":"15px","padding": "5px"}} src={images.url} alt=""/> */}
                                    </div>)}) : ''
                            }
                        </div>
                        <hr/>
                        <h4 className='ml-3'>Product Models</h4>
                        <div className='row'>
                            
                                {   (inputs.product_models && inputs.product_models !=="") ? 
                                     inputs.product_models.map((models,index)=>{
                                    
                                    return(                                        
 
                                        <div className='col-md-3 col-sm-6 col-12 m-1'>
                                            <div class="info-box bg-light">
                                                <div class="info-box-content">
                                                <span class="info-box-text text-center text-dark">{models.model_name}</span>
                                                
                                                {/* <a className='info-box-number text-center btn btn-xs btn-warning mr-2' href={models.models.model_url}>Download <i className='nav-icon fas fa-download'></i></a> */}
                                                <model-viewer alt="" src={models.models.model_url} ar environment-image="" poster="" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
                                                </div>
                                            </div>
                                        </div>) 
                                    }) :'' }
                               
                                    
                            
                        </div>

                        { loading ? <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>:''}          
                        <div className="card-footer clearfix">
                          <Link to="/products" className='btn btn-sm btn-success'>Back to Products</Link>
                        </div>   
                      </div>
                    </div>
            </div>
        </div>
    </section>
    
    </div>
 </>
  )
}

export default ProductView
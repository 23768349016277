import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      border:'1px solid #123',
      //fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#123',
      },
      '::placeholder': {
        color: '#123',
      },
    },
    empty:{
      border:'1px solid red',
    }, 
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
    
  }
};

function CardSection() {
  return <>
          <div className="form-control">
          <CardElement options={CARD_ELEMENT_OPTIONS} />
          </div>
        </>;
}
export default CardSection
import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const Navbar = () => {

  const navigate = useNavigate();
  const logoutUser = () => {
    localStorage.clear();
    window.location="/login";
    //navigate('/login');
  }

  return (
    <>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{"position": "fixed",
    "zIndex": "1037","right":"0","left": "0"}}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" data-widget="pushmenu" to={"#"} role="button"><i className="fas fa-bars"></i></Link>
        </li>
      </ul>    
      
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <Link className="nav-link" data-toggle="dropdown" to={"#"}>
            <i className="far fa-user"></i>
          </Link>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <Link to={"/profile"} className="dropdown-item">
              <div className="media">
              <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Profile
                    <span className="float-right text-sm text-danger"><i className="fas fa-user"></i></span>
                  </h3>
                </div>
              </div>           
            </Link>
            <div className="dropdown-divider"></div>
            <Link to={"#"} className="dropdown-item">
              <div className="media">
              <div className="media-body">
                  <h3 className="dropdown-item-title" onClick={() => { if (window.confirm('Are you sure you want to logout ?')) { logoutUser() }; }}>
                    Logout
                    <span className="float-right text-sm text-danger"><i className="fas fa-sign-out-alt"></i></span>
                  </h3>
                </div>
              </div>           
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  
  </>
  )
}

export default Navbar
import axios from 'axios';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast,ToastContainer } from 'react-toastify';

const Login = () => {
    
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const auth_token = localStorage.getItem("auth_token");
    
    const [loginInput,setLogin] = useState({
        email:'',
        error_list:[]
    });
    const handleInput =(e)=>{
        e.persist();
        setLogin({...loginInput,[e.target.name]:e.target.value});
    }

    const loginSubmit=(e)=>{
           e.preventDefault();
           const data = {
            email:loginInput.email
           }
           if(loginInput.email==""){
            toast.error('Enter Email address');
            return false;
            setLoading(false);
           }
           setLoading(true);
           axios.post('/login',data).then(res=>{
            if(res.data.status===200)
            {
                setLoading(false);
                localStorage.setItem("email",res.data.email);
                localStorage.setItem("auth_name",res.data.username);
                navigate('/otp');
            }else if(res.data.status===401){
                toast.error(res.data.message);
                setLoading(false);
            } else{
                setLogin({...loginInput, error_list: res.data.validation_errors });
                setLoading(false);
            }

           });
    }


  return (
    <>  <div className="hold-transition login-page">
        <div className="login-box">
            <div className="login-logo">
                <a href="../../index2.html"><b>Wiz</b>AR</a>
            </div>
             
            <div className="card">
            
                <>
                <div className="card-body login-card-body">
                <ToastContainer />
                <p className="login-box-msg">Sign in to start your session</p>
                { loading ? <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin text-dark"></i></div>:              
                <form onSubmit={loginSubmit}>
                
                    <div className="input-group mb-3">
                    <input type="email" name="email" onChange={handleInput} value={loginInput.email} className="form-control" placeholder="Email"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                        </div>
                    </div>
                    
                    </div>
                    <span>{loginInput.error_list.email}</span>
                    {/* <div className="input-group mb-3">
                    <input type="password" onChange={handleInput} value="" className="form-control" placeholder="Password"/>
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                        </div>
                    </div>
                    </div> */}
                    <div className="row">
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                    </div>
                    
                    </div>
                </form>
                }
                </div> 
                </>
              
                
            </div>
            
        </div>
        </div>
    </>
  )
}

export default Login
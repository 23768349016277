import axios from 'axios';
import React, { useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';


const Changepassword = () => {
    //const navigate = useNavigate();
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));

    
    const [passwordInput,setPasswordInput] = useState({
        old_password:'',
        new_password:'',
        confirm_password:'',
        error_list:[]
    });
    const handleInput =(e)=>{
        e.persist();
        setPasswordInput({...passwordInput,[e.target.name]:e.target.value});
        console.log(passwordInput);   
    }

    const updatePassword=(e)=>{
        e.preventDefault();
        const data = {
         userid:auth_user.id,   
         name:(passwordInput.name)? passwordInput.name : auth_user.name,
         old_password:(passwordInput.old_password)? passwordInput.old_password : auth_user.old_password,
         new_password:(passwordInput.new_password)? passwordInput.new_password : auth_user.new_password,
         confirm_password:(passwordInput.confirm_password)? passwordInput.confirm_password : auth_user.confirm_password
        }
        
       
        axios.post('/changepassword',data).then(res=>{
            console.log("updatePassword") 
         if(res.status===200)
         {
            alert(res.data.message);
            window.location.reload();
             console.log(res.data);
             
             
         } 

        }).catch(err => {
            
            setPasswordInput({...passwordInput, error_list:err.response.data.message});

             
        });
 }

    return (
        <>

            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Change Password </h1>
                                
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Change Password</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content" style={{"height":"570px"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-md-8 mx-auto mt-5'>
                            <div className="card card-info">
                                <div className="card-header">
                                    <h3 className="card-title">Change Password</h3><br/>
                                    
                                </div>
                                <form  onSubmit={updatePassword} className="form-horizontal">
                                    <div className="card-body">
                                    <p className='text-danger' >  { passwordInput ? passwordInput.error_list  :''}</p>
                                        <div className="form-group row">
                                            <label  className="col-sm-3 col-form-label">Old Password</label>
                                            <div className="col-sm-9">
                                                <input type="password" className="form-control"  name='old_password' id="inputEmail3" placeholder="Current Password" onChange={handleInput} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label  className="col-sm-3 col-form-label">New Password</label>
                                            <div className="col-sm-9">
                                                <input type="password" name='new_password' className="form-control" id="inputEmail3" placeholder="New Password"  onChange={handleInput}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label  className="col-sm-3 col-form-label">Confirm Password</label>
                                            <div className="col-sm-9">
                                                <input type="password" name='confirm_password' className="form-control" id="inputEmail3" placeholder="Confirm Password"  onChange={handleInput}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-info">Update </button>
                                        
                                    </div>
                                    
                                </form>
                            </div>
                            </div>  
                        </div>
                    </div>

                </section>
            </div>
            <footer className="main-footer">
                <strong>Copyright &copy; 2023-2024 <a href="/">wizAR</a>.</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.0.4
                </div>
            </footer>

        </>
    )
}

export default Changepassword
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const RequestsEdit = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [feedbacklist, setFeedbackList] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    const config = {
        headers: {
            Authorization: bearer_token
        }
    };
    useEffect(() => {
        fetchProduct();
    }, []);
    const auth_user = JSON.parse(localStorage.getItem("auth_user"));

    const fetchProduct = () => {
        setLoading(true);
        axios.get('/requests/' + id + '/edit', config).then((res) => {
            setFeedbackList(res.data.data.feedbacks);
            setInputs({
                customer_name: res.data.data ? res.data.data.users.name :'NULL',
                feedback:null,
                product_name: res.data.data.product_name,
                frontend_url: res.data.data.frontend_url,
                status: res.data.data.status,
                product_images: res.data.data.product_images,
                product_models: res.data.data.product_models
            });
            setLoading(false);
        });
    }

    const [feedback, setFeedback] = useState('');
    const handleFeedbackChange =(event)=>{
        setFeedback(event.target.value);
    } 
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target.value === "2") {
            document.getElementById('feedback').style.display = "block";
        } else {
            document.getElementById('feedback').style.display = "none";
        }

        setInputs(values => ({ ...values, [name]: value }))
    }

    const rejectProduct = ()=>{
        console.log("id"+id);
        setShowFeedback(true);
        inputs.status=2;
    }

    const acceptRequest = () =>{
        
        const bodyParameters = {
            id: id,
            status:3
        };

        setLoading(true);
        axios.post('/requestAccepted', bodyParameters, config).then((res) => {
            if(res.status===200)
            {  
                setLoading(false);
                toast.success(res.data.message);
                navigate('/products');
            } else {
                setLoading(false);
                toast.error(res.data.message);
            }
            //window.location.reload();
            //navigate('/');
        }).catch(function(error) {
            setLoading(false);
            toast.error(error.response);
          })
        
        //window.location="/products";
    }


    const submitForm = (e) => {
        
        e.preventDefault();
        if(inputs.status===2 && feedback==="")
        {
            toast.error("feedback cannot be null");
            return false;
        }
        let formData = new FormData();
        // Array.from(files).forEach(item => {
             
        //     formData.append("filedata[]", item);
        // });
        formData.append('inputs', JSON.stringify(inputs));
        formData.append('feedbackdetail', feedback);
        formData.append('userid', auth_user.id);
        formData.append('id', id);



        const bodyParameters = {
            inputs: inputs,
            id: id,
            feedback:feedback
        };

        setLoading(true);
        axios.post('/requestUpdate', formData, config).then((res) => {
          
            
            if(res.status===200)
            {  
                setLoading(false);
                toast.success(res.data.message);
                navigate('/requests');
            } else {
                setLoading(false);
                toast.error(res.data.message);
            }
            //window.location.reload();
            //navigate('/');
        }).catch(function(error) {
            setLoading(false);
            toast.error(error.response);
          })

    }

    return (
        <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Request Edit</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Request Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                <ToastContainer />
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="card card-dark">
                                <div className="card-header">
                                    <h3 className="card-title">Request Edit</h3>

                                    <div className="card-tools">
                                        <Link to="/requests" className='btn btn-sm btn-primary'>Back </Link>
                                        {/* <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                { loading ? 
                                <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div> :
                                <form onSubmit={submitForm} enctype="multipart/form-data">   
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label for="inputName">Product Name</label>
                                                <input type="text" name='product_name' id="inputName" readOnly placeholder='Product Name' className="form-control" value={inputs.product_name || ''} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label for="inputName">Customer Name</label>
                                                <input type="text" name='customer_name' id="inputName" readOnly placeholder='Product Name' className="form-control" value={inputs.customer_name || ''} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label htmlFor="inputSpentBudget">Frontend URL</label>
                                                <input type="text" readOnly name='frontend_url' id="inputSpentBudget" className="form-control" value={inputs.frontend_url || ''}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='form-group mt-4'>
                                                <label>You have new Product Request</label>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <button type='button' onClick={()=>rejectProduct()} className='btn btn-sm btn-danger mr-3 mt-4'>Reject</button>
                                            <button type='button' onClick={() => { if (window.confirm('Are you sure you want to Accept this product Request ?')) { acceptRequest();  }; }}  className='btn btn-sm btn-success mt-4'>Accept</button>
                                            {/* <div className="form-group">
                                                <label for="inputStatus">Status</label>
                                                <select id='current-status' value={inputs.status} name='status' onChange={handleChange} className='form-control'>
                                                    <option value='select'>Select</option>
                                                    <option value="1" selected >Is Requested</option>
                                                    <option value="2" selected>Is Rejected</option>
                                                    <option value="3" selected>In Progress</option>
                                                    <option value="4" selected>Is Reviewed</option>
                                                </select>
                                            </div> */}
                                        </div>
                                    </div>
                                    {showFeedback ? 
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div id="feedback" >
                                                
                                                    <div className='form-group'>
                                                        <label>Feedback</label>
                                                        <textarea  name='feedback' className='form-control' style={{"border":"1px solid #000"}}  value={feedback} onChange={handleFeedbackChange}></textarea>
                                                    </div>
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className="form-group">
                                                <label for="inputName">Image</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {(inputs.product_images && inputs.product_images !== "") ?
                                            inputs.product_images.map((images, index) => {
                                                return (
                                                    <div className="col-md-5 card ml-2" key={index}>
                                                        <img className="card-img-top  " src={images.url} alt="" />
                                                        <div className="card-body text-center p-2">
                                                            <h5 className="card-title float-none">{images.file_name}</h5>
                                                            {/* <p className="card-text "><button className='btn btn-xs btn-danger'>Remove</button></p> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''}
                                    </div>  
                                     



                                    
                                                              
                                    <div className='card card-secondary'>
                                        <div className="card-header">
                                        <h3 className="card-title">Feedbacks</h3>
                                        </div>
                                        <div className='card-body'>
                                        <div class="row">

                                                <div class="col-md-12 mx-auto">
                                                <div class="timeline">
                        {feedbacklist ? feedbacklist.map((feed, index) => (
                            <>
                            <div class="time-label">
                            <span class="bg-red"> { moment(feed.created_at).format("DD/MM/YYYY")  }</span>
                        </div>
                        { feed.feedback_type==="2" ?  
                            <div>
                                <i class="fas fa-envelope bg-blue"></i>
                                <div class="timeline-item">
                                    <span class="time"><i class="fas fa-clock"></i> { moment(feed.created_at).format("hh:mm a") }</span>
                                    <h3 class="timeline-header"><span className='text-bold text-primary text-capitalize'>{feed.users[0].name}</span> </h3>
                                    <div class="timeline-body">
                                        { feed.feedback_text}
                                    </div>
                                </div>
                            </div>
                            :
                        <div>
                            <i class="fas fa-user bg-green"></i>
                            <div class="timeline-item">
                                <span class="time"><i class="fas fa-clock"></i>  { moment(feed.created_at).format("hh:mm a") }</span>
                                <h3 class="timeline-header no-border"><span className='text-bold text-success text-capitalize'>{feed.users[0].name}</span> </h3>
                                <div className='timeline-body'>{ feed.feedback_text}</div>
                            </div>
                        </div>
         } 
            </>

            ))    : ''}
            <div>
                <i class="fas fa-clock bg-gray"></i>
            </div> 
        
        
                                                </div>
                                                
                                                </div>
                                            </div>
                                        </div>
       
                                    </div> 
                                    
                                    
                                    
                                    
                                    {inputs.status==="3" || inputs.status==="4" ? 
                                    <div className='row mb-3'>
                                            <div className='col-md-6 ml-3'>
                                                <label for="exampleFormControlFile1">Upload glb & Usdz Modal</label>
                                                <input type="file" onChange={(e) => {
                                                    setFiles(e.target.files)
                                                }} multiple accept='model/gltf-binary'/>
                                            </div>
                                            <div className='col-md-12'>
                                                <hr />
                                                <h4 className='ml-3'>Product Models</h4>
                                                <div className="col-md-12 card-deck">
                                                    {(inputs.product_models && inputs.product_models !== "") ?
                                                        inputs.product_models.map((models, index) => {

                                                            return (
                                                                <div className="col-md-3 card" key={index}>
                                                                    <div className="card-body text-center p-2">
                                                                        <h5 className="card-title float-none">{models.model_name}</h5>

                                                                        <p className="card-text ">
                                                                            {/* <a className='btn btn-xs btn-warning mr-2' href={models.models.model_url}>Download</a> */}
                                                                            {/* <button className='btn btn-xs btn-danger'>Remove</button> */}
                                                                        </p>
                                                                    </div>
                                                                    <model-viewer alt="" src={models.models.model_url} ar environment-image="" poster="" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
                                                                </div>

                                                            )
                                                        })

                                                        : ''}
                                                </div>
                                            </div>
                                    </div>
                                    :''}

                                    <div className="card-footer clearfix">
                                        <button type="submit" className='btn btn-sm btn-success'>Save </button>
                                        <button className='btn btn-sm btn-default ml-2'>Cancel</button>
                                    </div>




                                </form>
                                 }
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
            <Footer></Footer>
        </>
    )
}

export default RequestsEdit
import React ,{useState} from 'react';
import '../admin/plans.css';
//import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios'; 
import { toast,ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
//const stripePromise = loadStripe('pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3');

const Plans = () => {
  
  const [loading, setLoading] = useState(false);
  const [showPayBtn, setShowPayBtn] = useState(false);
  const [pAmount, setPAmount] = useState(0);
  const [pID, setPID] = useState(0);

    const proceedToPay=()=>{
        let selectedPlan  = "";
        var ele = document.getElementsByName('plan');
              
            for(var i = 0; i < ele.length; i++) {
                if(ele[i].checked)
                  selectedPlan  = ele[i].value;
             
            }
           
           //document.getElementById("selected_plan_amount").value=selectedPlan;
           let selectedPlanName='';
           
          if(selectedPlan==="1"){
            selectedPlanName='FREE PLAN';
            
            document.getElementById("selected_plan_amount").value=0;
            document.getElementById("plan_id").value=selectedPlan;
            setPAmount(0);
          }
          if(selectedPlan==="2"){
              selectedPlanName='BASIC PLAN';
              document.getElementById("selected_plan_amount").value=10;
              document.getElementById("plan_id").value=selectedPlan;
              setPAmount(10);
          }
          if(selectedPlan==="3"){
              selectedPlanName='PRO PLAN';
              document.getElementById("selected_plan_amount").value=30;
              document.getElementById("plan_id").value=selectedPlan;
              setPAmount(30);
          }
          setPID(selectedPlan);
          

          document.getElementById("selected_plan_name").innerHTML=selectedPlanName;
            
    }

    async function handleCheckout(e) {
        e.preventDefault();
        var name = document.getElementById("customer_name").value;
        var email = document.getElementById("customer_email").value;
        var contact_number = document.getElementById("contact_number").value;
        var amount =  document.getElementById("selected_plan_amount").value;

        var plan_id = document.getElementById('plan_id').value;
        let selectPlan='';
        if(plan_id==="1"){
            selectPlan='FREE PLAN';
        }
        if(plan_id==="2"){
            selectPlan='BASIC PLAN';
        }
        if(plan_id==="3"){
            selectPlan='PRO PLAN';
        }

        
        if(name==="")
        {

            toast.error('Name is required field');
            return false;
        }
        if(email==="")
        {
          toast.error('Email address is required field');
          return false;
        }
        if(contact_number==="")
        {
          toast.error('Contact number is required field');
          return false;
        }
        const payload={
            'customer_name': name,
            'email': email,
            'contact_number':contact_number,
            'selectedPlan':selectPlan,
            'plan_id':plan_id,
            'amount':amount
        }
          
         setLoading(true);
         axios({
            method: 'post',
            url: '/send-mail',
            data: payload, // you are sending body instead
            headers: {
             // 'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
            }, 
          }).then(response => {
            console.log("respose is"+JSON.stringify(response));
            if (response.data.success===true) {
                setLoading(false);
                window.location = "/success"; 
                console.log("successfully send Redirect to OTP PAGE");
            }
            if(response.data.success===false){
              setLoading(false);
              toast.error('Email already taken'); 
              //window.location.reload();
            }        
            
        }).catch((error) => {
           
        })

   
    }

   const selectPlan=(event)=>{
           
          //toast(event.target.value);
          if(event.target.value!=="")
          {
            setShowPayBtn(true);
          }
   }

  return (
    <>
          <div className="container mt-5">
            <div className='row justify-content-md-center'>
              <div className='col-md-auto'><h3 className='text-warning'><b>WizAR</b></h3></div>
            </div>
            <div className="row">
                                
                    <div className="col-md-4 col-lg-4 col-sm-4" >
                        <label>
                          <input  onChange={selectPlan.bind(this)} type="radio" name="plan" className="card-input-element" id="selected-plan" value="1" />
                            <div className="card card-default bg-light bg-gradient card-input" style={{"maxWidth":"100%"}}>
                              <div className="card-header text-center">FREE PLAN</div>
                              <div className="card-body">
                              <h4>FREE $0 </h4>
                               <span>Click to select plan</span>
                               <p>After Free plan $0 applicable</p>
                               <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                              </div>
                            </div>

                        </label>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                        <label>
                          <input onChange={selectPlan.bind(this)} type="radio" name="plan"  className="card-input-element" value="2" />
                            <div className="card card-default bg-secondary bg-gradient  card-input" style={{"maxWidth":"100%"}}>
                              <div className="card-header text-center">BASIC PLAN</div>
                              <div className="card-body">
                                <h4>Cost $10</h4>
                                
                                 <span>Click to select plan</span>
                               <p>Price applicable as displayed</p>
                               <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                              </div>
                            </div>
                        </label>                    
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                        <label>
                          <input onChange={selectPlan.bind(this)} type="radio" name="plan" className="card-input-element" value="3"/>
                            <div className="card card-default bg-dark bg-gradient card-input" style={{"maxWidth":"100%"}}>
                              <div className="card-header text-center">PRO PLAN</div>
                              <div className="card-body">
                              <h4>Cost $30</h4>
                              <span>Click to select plan</span>
                               <p>Price applicable as displayed</p>
                               <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                              </div>
                            </div>
                        </label>                    
                    </div>
                
            </div>
            <div className="row mt-5">
                <div className="col-md-4"></div>
                <div className="col-md-4 ">
                    <center>
                    { showPayBtn ? <button type="button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={proceedToPay}>Register & Pay Now</button> :
                      <span className='text-lg text-muted'>Select Plan To Get Started</span>
                    }
                                      <br></br>
                    <Link to="/login">Already Memeber ? Click to Login</Link>
                </center>
                </div>
                <div className="col-md-4"></div>
                
            </div>
         
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                        Hey you have selected <span id="selected_plan_name"></span> , we need little more information about you.</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                  <ToastContainer/>
                  { loading ? <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin text-white"></i></div> :
                    <form>
                    <input type="hidden" name='amount' value={pAmount} id="selected_plan_amount"/>
                    <input type="hidden" name='plan_id' value={pID} id="plan_id"/>
                    
                      <div className="form-group">
                        <label htmlFor="recipient-name" className="col-form-label">Name : <span className='required'>*</span></label>
                        <input type="text" className="form-control"  id="customer_name" required/>
                        
                      </div>
                      <div className="form-group">
                        <label htmlFor="message-text" className="col-form-label">Email Address : <span className='required'>*</span></label>
                        <input type="email" className="form-control" id="customer_email" required/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="message-text" className="col-form-label">Contact Number : <span className='required'>*</span></label>
                        <input type="email" className="form-control" id="contact_number" required maxLength={10} pattern="/^[0-9\b]+$/" />
                      </div>
                     
                    </form>
                  }  
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={handleCheckout}>Register</button>
                  </div>
                </div>
              </div>
            </div>

             
        </div>  
    </>
  )
}

export default Plans
import {
    createContext,
    useReducer,
  } from "react";
   
  export const AuthContext = createContext();
  
  export const AuthContextProvider = ({ children }) => {
 
    
   const INITIAL_STATE = {
        user: {}
         // ,
        // email:'',
        // token:'',
        // permission:''
    };
  
    const userReducer = (state, action) => {
      switch (action.type) {
        case "AUTH_USER":           
        return {           
            user: action.payload
        };
  
        default:
          return state;
      }
    };
  
    const [state, dispatch] = useReducer(userReducer, INITIAL_STATE);
  
    return (
      <AuthContext.Provider value={{ data:state, dispatch }}>
        {children}
      </AuthContext.Provider>
    );
  };
  
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const UserPayment = () => {
  const [userplans,setUserPlans] = useState([]);

  const [pagination,setPagination] = useState('');
  const [currPage,setCurrPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastpage, setLastPage] = useState(0);

  const [pageTotal, setPageTotal] = useState(1);
  let countPerPage = 10;

  //For search fields
  const [inputs,setInputs] = useState({});
  const [sortField,setSortField] = useState();
  const [username,setUsername] = useState(null);
  const [userplan,setUserPlan]     = useState(null);

  
  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
  const config = {
    headers: {
      Authorization: bearer_token
    }
  };
  const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  const userRole = auth_user.roles[0]['title'];
  useEffect(()=>{
    fetchUserPlans({page,countPerPage,username,userplan});
  },[])
  
  
  const fetchUserPlans=({page,countPerPage,username,userplan})=>{
    console.log("page"+page+"countperpage"+countPerPage);
    setLoading(true);
        const auth_user = JSON.parse(localStorage.getItem("auth_user"));
        const url = `/userpayment?page=${page}&per_page=${countPerPage}&name=${username}&userplan=${userplan}`;
        axios.get(url,config).then(res=>{

          if(res.status===200)
          {
            console.log(res.data.data);
            setUserPlans(res.data.data);
            setPagination(res.data.links);
            setLastPage(res.data.last_page);
            setCurrPage(res.data.current_page);
            setLoading(false);
            setPage(res.data.from);  
            setPageTotal(res.data.total);
            setLoading(false);
          } 
        });
  }

  const setNameChange = (e)=>{
    setUsername(e.target.value);
  }
   
  const setUserPlanChange = (e)=>{
     setUserPlan(e.target.value);
  } 

  const submitForm = (e) =>{
    setLoading(true);
    setPagination('');
    let custPage = 1;
    e.preventDefault();
    fetchUserPlans({custPage,countPerPage,username,userplan});
  } 
  const clearForm=()=>{
    window.location.reload();
  }

  const [order, setOrder] = useState("ASC");
  function applySorting(col) {
    console.log("sorting"+col);
    if(order==="ASC")
    { 
      const sorted = [...userplans].sort((a,b)=>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUserPlans(sorted);
      setOrder("DSC");
      
    }
    if(order==="DSC")
    {
      const sorted = [...userplans].sort((a,b)=>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUserPlans(sorted);
      setOrder("ASC");
    }
    setSortField(col);
  }

  const getPreviousRecords=({id})=>{
    console.log("previous"+id);
  }
  const getNextRecords=(id)=>{
    let page = id;
    setPage(page);
    console.log("PAGE ID"+id+"userplan"+userplan);
    fetchUserPlans({page,countPerPage,username,userplan});
  }

  return (
    <>
        <Navbar/>
        <Sidebar></Sidebar>
        <div className="content-wrapper" >
          <div className="content-header">
              <div className="container-fluid">
                  <div className="row mb-2">
                  <div className="col-sm-6">
                      <h1 className="m-0 text-dark">Payments</h1>
                  </div>
                  <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active">Payments</li>
                      </ol>
                  </div>
                  </div>
              </div>
          </div> 
          {userRole ==="Admin" ? <> 
            <section className='content'>
              <div className="container-fluid">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Search </h3>
                  </div>
                  <div className="card-body">
                  
                    <div className="row">
                      <div class="col-2"></div> 
                      <div className="col-3">
                        <input type="text" name='name' onChange={setNameChange} value={ username || ''} className="form-control" placeholder="Enter Name"/>
                      </div>
                      <div className="col-3">
                        <select name="user_plan"  value={inputs.user_plan} onChange={setUserPlanChange} className='form-control' >
                          <option value="select"  >Select Plans</option>
                          <option value="1" selected={ inputs.user_plan===1 ?'selected'  :''} >Free</option>
                          <option value="2" selected={ inputs.user_plan===2 ? 'selected'  :''}>Basic</option>
                          <option value="3" selected={ inputs.user_plan===3 ? 'selected'  :''}>PRO</option>
                        </select>
                      </div>
                      <div className="col-2 d-flex">
                        <button type="button" onClick={submitForm}  className='btn btn-sm btn-primary mr-1 '>Search</button>
                        { username || userplan  ? 
                        <button type="button" onClick={clearForm}  className='btn btn-sm btn-danger mr-1 '>Clear</button> : ''}
                      </div>
                      <div class="col-2"></div>
  
                    </div>
                  
                  </div>
                  
                </div>
              </div>
            </section> 
            </>
          : ''}      
        <section className="content">
              <div className="container-fluid">
                  <div className='row'>

                      
                  </div>
                  <div className='row'>
                      <div className='col-12'>
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Payments Listing</h3>
                          </div>
                  
                          <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                              <thead>
                              <tr>
                                <th onClick={() => applySorting("id")}>Id { sortField==="id"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                                <th onClick={() => applySorting("name")}>Name { sortField==="name"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                                <th onClick={() => applySorting("plan_name")}>Plan { sortField==="plan_name"  ? order==="ASC" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>:'' }</th>
                                <th>Amount</th>
                                <th>Customer ID</th>
                                <th>Subscription ID</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              {  userplans!=""   ? 
                                  userplans.map((userplan, index) =>  (
                                  <tr key={userplan.id}>
                                  <td>{index+1}</td>
                                  <td>{userplan.name}</td>
                                  <td>{ userplan.plans != ""?  userplan.plans[0].plan_name : ''}</td>
                                  <td>{ userplan.plans != ""? userplan.plans[0].amount  : ''}</td>
                                  <td>{ userplan.plans != ""? userplan.plans[0].customer_id : ''}</td>
                                  <td>{ userplan.plans != ""? userplan.plans[0].subscription_id : ''}</td>
                                  <td>{ userplan.plans != ""? userplan.plans[0].subscription_status : ''}</td> 
                                  
                                  <td> 
                                      
                                      <Link className='btn btn-sm btn-info ml-1' to={{ pathname:'/userpayments/'+userplan.plans[0].id}}><i className='fas fa-eye' ></i></Link> 
                                      
                                  </td>
                                  </tr>
                                  ))   
                                  :<tr><td colSpan={8} className="text-center" >No Records Found</td></tr>
                              }                     
                              </tbody>
                            </table>
                          </div>
                          { loading  ? 
                        <div className="overlay"><i className="fas fa-2x fa-sync-alt fa-spin"></i></div>          
                        : ''}
                          <div className="card-footer clearfix">
                          <nav aria-label="Page navigation example">
                          <ul class="pagination justify-content-end">
                            {pagination ? pagination.map((pagi,index)=>(
                              <>
                                <li className={ pagi.url===null ? 'page-item disabled' :  pagi.active ?  'page-item active'  : 'page-item '}>
                                    { pagi.url===null ?
                                          <span class="page-link" onClick={() => {
        getPreviousRecords(pagi.label)
    }} >{pagi.label}</span> 
                                          : pagi.active===true ? 
                                          <span class="page-link active">
                                                {pagi.label} 
                                              <span class="sr-only">(current)</span>
                                              </span> :
                                                 pagi.label==="Next" ? 
                                                 <button class="page-link"  onClick={() => {
                                                    getNextRecords(lastpage
)
                                                }}    > {pagi.label} 
                                              </button>
                                                   :
                                              <button class="page-link"  onClick={() => {
                                                    getNextRecords(pagi.label)
                                                }}    > {pagi.label} 
                                              </button> 
                                    }
                                </li>
                              </>
                            )) :'' }
                          </ul>
                        </nav>
                        { pagination ? 
                         <> <p className='float-right'>showing {page} of {pageTotal}</p></> 
                          :''
                         } 
                          </div>   
                        </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
        <Footer></Footer>
    </>
  )
}

export default UserPayment
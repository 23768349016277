import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from '../admin/Navbar';
import Sidebar from '../admin/Sidebar';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProductEdit = (props) => {
    const { id } = useParams();
    const [inputs, setInputs] = useState({});
    const [files, setFiles] = useState([]);
    const [feedbacklist, setFeedbackList] = useState([]);
    const [loading, setLoading] = useState(false);
    const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
    
    const config = {
        headers: {
            Authorization: bearer_token
        }
    };
    useEffect(() => {
        fetchProduct();
    }, []);


    const fetchProduct = () => {
        setLoading(true);
        axios.get('/products/' + id + '/edit', config).then((res) => {

            setFeedbackList(res.data.feedbacks);
            //console.log("response" + res.data.feedbacks);
            setInputs({
                product_name: res.data.product_name,
                frontend_url: res.data.frontend_url,
                feedback: res.data.feedback,
                status: res.data.status,
                product_images: res.data.product_images,
                product_models: res.data.product_models,
            });
            setLoading(false);
        });
    }



    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;


        if (event.target.value === "2") {
            document.getElementById('feedback').style.display = "block";
        } else {
            document.getElementById('feedback').style.display = "none";
        }

        setInputs(values => ({ ...values, [name]: value }))
    }

    const [feedback, setFeedback] = useState('');
    const handleFeedbackChange = (event) => {
        
        setFeedback(event.target.value);
    }

    const auth_user = JSON.parse(localStorage.getItem("auth_user"));
  

    const userRole = auth_user.roles[0]['title'];

    const deleteModel = (id)=>{
        const bodyParameters = {
            id: id
        };
      
          axios.post('/deleteModel', bodyParameters,config).then((res) => {
            setLoading(false);
            toast.success(res.message);
            window.location.reload();
            //navigate('/');
        }).catch((error)=>{
            toast.error(error.response.data.message);
            setLoading(false);
            
        })
    }
    const submitForm = (e) => {
        
        setLoading(true);
        e.preventDefault();
        if(inputs.status==2 && feedback==="")
        {
            toast.error("Feedback cannot be empty");
            setLoading(false);
            return false;
        }
       
        let formData = new FormData();
        Array.from(files).forEach(item => {
            formData.append("filedata[]", item);
        });

        formData.append('inputs', JSON.stringify(inputs));
        formData.append('feedbackdetail', feedback);
        formData.append('userid', auth_user.id);
        formData.append('id', id);

        
        axios.post('/uploadModal', formData).then((res) => {
            setLoading(false);
            toast.success(res.message);
            window.location.reload();
            //navigate('/');
        }).catch((error)=>{
            toast.error(error.response.data.message);
            setLoading(false);
            
        })
    }


    return (
        <>
            <Navbar />
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Products</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link href="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Product Edit</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                <ToastContainer />

                    <div className="container-fluid">
                        <form onSubmit={submitForm} enctype="multipart/form-data">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="card card-dark">
                                        <div className="card-header">
                                            <h3 className="card-title">Product Edit</h3>
                                            <Link to="/products" className='btn btn-sm btn-primary float-right'>Back</Link>
                                        </div>

                                        <div className="card-body">
                                            <div className='row'>

                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label htmlFor="inputEstimatedBudget">Product Name</label>
                                                        <input readOnly="readOnly" type="text" name='product_name' id="inputEstimatedBudget" className="form-control" value={inputs.product_name || ''}
                                                            onChange={handleChange}  />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        
                                                        <label htmlFor="inputEstimatedDuration">Current Status 
                                                        </label>
                                                        {
                                                            inputs.status==="5" ? <span className="badge bg-success text-white">Is Published</span> : ''
                                                        }                                                        
                                                        {
                                                            inputs.status==="4" ? <span className="badge bg-dark text-white">In Review</span> : ''
                                                        }
                                                        {
                                                            inputs.status ==="3" ? <span className="badge bg-primary">In Progress</span> : ''
                                                        }
                                                        {
                                                            inputs.status ==="2" ? <span className="badge bg-danger">Is Rejected</span> : ''
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className="form-group">
                                                        <label htmlFor="inputSpentBudget">Frontend URL</label>
                                                        <input type="text" readOnly="readOnly" name='frontend_url' id="inputSpentBudget" className="form-control" value={inputs.frontend_url || ''}
                                                            onChange={handleChange}  />
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <div id="feedback">
                                                        {inputs.status === "2" ?
                                                            <div className='form-group'>
                                                                <label>Feedback</label>
                                                                <textarea name='feedback' className='form-control' value={feedback} onChange={handleFeedbackChange}></textarea>
                                                            </div>
                                                            : ''}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="row">

                                            <div class="col-md-10 mx-auto">
                                             {  feedbacklist.length>0 ?
                                                <h4 className='p-3 mb-2 text-info text-bold'>Feedback</h4>
                                                :""}

                                                <div class="timeline">
                                                    {feedbacklist ? feedbacklist.map((feed, index) => (
                                                        <>
                                                        <div class="time-label">
                                                        <span class="bg-red"> { moment(feed.created_at).format("DD/MM/YYYY")  }</span>
                                                    </div>
                                                    { feed.feedback_type==="2" ?  
                                                        <div>
                                                            <i class="fas fa-envelope bg-blue"></i>
                                                            <div class="timeline-item">
                                                                <span class="time"><i class="fas fa-clock"></i> { moment(feed.created_at).format("hh:mm a") }</span>
                                                                <h3 class="timeline-header"><span className='text-bold text-primary text-capitalize'>{feed.users[0].name}</span> </h3>
                                                                <div class="timeline-body">
                                                                    { feed.feedback_text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                      :
                                                    <div>
                                                        <i class="fas fa-user bg-green"></i>
                                                        <div class="timeline-item">
                                                            <span class="time"><i class="fas fa-clock"></i>  { moment(feed.created_at).format("hh:mm a") }</span>
                                                            <h3 class="timeline-header no-border"><span className='text-bold text-success text-capitalize'>{feed.users[0].name}</span> </h3>
                                                            <div className='timeline-body'>{ feed.feedback_text}</div>
                                                        </div>
                                                    </div>
                                                     } 
                                                        </>

                                                        ))    : ''}
                                                        { feedbacklist.length >0 ? 
                                                            <div>
                                                            <i class="fas fa-clock bg-gray"></i>
                                                        </div> 
                                                          :'' }
                                                        
                                                    
                                                    
                                                </div>
                                                
                                            </div>
                                        </div>



                                        <hr />
                                        <div className='row'>
                                            <div className='col-md-12'>

                                                <h4 className='ml-3'>Product Images</h4>
                                                <div className="col-md-12 card-deck">
                                                    {(inputs.product_images && inputs.product_images !== "") ?
                                                        inputs.product_images.map((images, index) => {
                                                            return (

                                                                <div className="col-md-3 card" key={index}>
                                                                    <img className="card-img-top border " src={images.url} alt="" />
                                                                    <div className="card-body text-center p-2">
                                                                        <h5 className="card-title float-none">{images.file_name}</h5>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })

                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        
                                        <div className='row mb-3'>
                                            { inputs.status === "3" || inputs.status === "4" ?
                                            <div className='col-md-6 ml-3'>
                                                <label for="exampleFormControlFile1">Upload glb & Usdz Modal</label>
                                                <input type="file" onChange={(e) => {
                                                    setFiles(e.target.files)
                                                }} multiple   />

                                                


                                            </div>
                                            : '' }

                                            <div className='col-md-12'>
                                                
                                                <h4 className='ml-3'>Product Models</h4>
                                                {inputs.status === "5" ? 
                                                <div className="col-md-12 card-deck">
                                                    {(inputs.product_models && inputs.product_models !== "") ?
                                                        inputs.product_models.map((models, index) => {

                                                            return (
                                                                <div className="col-md-3 card" key={index}>
                                                                    <div className="card-body text-center p-2">
                                                                        <h5 className="card-title float-none">{models.model_name}</h5>

                                                                        <p className="card-text ">
                                                                            {/* <a className='btn btn-xs btn-warning mr-2' href={models.models.model_url}>Download</a> */}

                                                                            <p className="card-text "><button type='button' onClick={() => { if (window.confirm('Are you sure you want to delete this model ?')) { deleteModel(models.models.id);  }; }} className='btn btn-xs btn-danger'>Remove</button></p>
                                                                             
                                                                        </p>
                                                                    </div>
                                                                    <model-viewer alt="" src={models.models.model_url} ar environment-image="" poster="" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
                                                                </div>

                                                            )
                                                        })

                                                        : ''}
                                                </div>
                                                :''}
                                            </div>
                                        </div>
                                        
                                        {loading ?
                                            <div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>
                                            : ''}
                                        {loading ? 'Please Wait Loading' :
                                            <div className="card-footer clearfix">

                                                <button type="submit" className='btn btn-sm btn-success'>Save Changes</button>
                                                <button className='btn btn-sm btn-default ml-2'>Cancel</button>

                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                </section>

            </div>
        </>
    )
}

export default ProductEdit
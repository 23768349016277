import React ,{useEffect, useState} from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {
  
  Elements
  
} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { useParams } from 'react-router-dom';
import axios from 'axios';



const Payment = () => {
  let { email } = useParams();
  
  const stripePromise = loadStripe('pk_test_51LIneAJhPDAF2mzKKCwoBEn08quFAH1T33hqshsKJQjlkEhzPZCRkPgzwBYTJFMlAs2ewY3P8UNll7CJIVZmmIOu00bjy6LHXr');
  
  const bearer_token = `Bearer ${localStorage.getItem('auth_token')}`;
  const config = {
    headers: {
      Authorization: bearer_token
    }
  };

  useEffect(()=>{
    getUserData();
},[]);
     
    
    const [planID,setPlanID]=useState('');
    const [planName,setPlanName]=useState('');
    const [planAmount,setPlanAmount]=useState('');
    const [loading,setLoading]=useState('');
    const getUserData = ()=>{
  
    let url = '/getuserdata';
    const bodyParameters = {
      email: email
    };

    
    setLoading(true);
    axios.post(url,bodyParameters,config).then(res=>{
        if(res.status===200)
        {
          setPlanID(res.data.plan.plan_id);
          setPlanName(res.data.plan.plan_name);
          setPlanAmount(res.data.plan.amount);
          setLoading(false);
          localStorage.setItem("plan",JSON.stringify(res.data.plan));
          localStorage.setItem("email",email);
          //console.log(res.data.plan);
        }else if(res.data.status===401){
            alert('Invalid Credentials');
        } else{
            
        }
    });
    
  }




  return (
    <>
        <>
    
    <center>
    
      <div className="card card-dark" style={{width:"600px",marginTop:"100px"}} >
      { loading  ? 
                        <div class="overlay"><i class="fas fa-2x fa-sync-alt fa-spin"></i></div>          
    : ''}
        <div className='card-header'>Complete Your Payment</div>
        <h5>Your Email {email} </h5>
        <div className="card-body">
        <div className="product-info">
          <h3 className="product-title">{planName}</h3>
          <h4 className="product-price">${planAmount}</h4>
        </div> 
        <Elements stripe={stripePromise}  >
            <CheckoutForm   />
          </Elements>
        </div>
      </div>
    </center>
    </>
    </>
  )
}

export default Payment